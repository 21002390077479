let shortListElm = $('#shortlist');
const formLoginAssist = $('#assist-login-form');

/*	stop preventing tying - or + in phone number input	*/
document.getElementById("assist_login_phone").addEventListener("keydown", function (e) {
		if (invalidChars.includes(e.key)) e.preventDefault();
});

const assistHideLoginBtnSpin = () => spinBtnLoaderElm.hide();

function loginSuccessAssist() {
		$('#submitAssitant').css('pointer-events','none').html(`Loading <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>`);
		// $('#assistLogin').hide();
		$('#MobileText').show();
		if (user) $('.mobile').html(user.phone);
}

$(function () {

		lostForm.off().on('submit', function (e) {
				e.preventDefault();
				// const ISD_CODE = $('#assist_countryCode .current-isdcode').text().trim().replace('+', '');
				const ISD_CODE = $('#assist-login-form .iti__selected-dial-code').text().trim().replace('+', '');
				let AssistMobNo = $('#assist_login_phone').val().replace(/ /g, '');
				if (AssistMobNo.split('')[0] === "0") AssistMobNo = AssistMobNo.replace('0', '');
				let phone = ISD_CODE === '91' ? AssistMobNo : ISD_CODE + "-" + AssistMobNo;
				login(phone, $('#user_login_password').val());
		});

});

function resendOtp(event) {
		event.preventDefault();
		let loginFormErrorElm = $('.login-form-error');
		let assistLoginEmailElm = $('#assist-login-email');
		if (validateFormLoginAssist('resend')) {
				loginFormErrorElm.empty();
				showLoginBtnSpin();
				grecaptcha.execute(grecaptchaWidgetId2);
		} else {
				if (!loginFormErrorElm.html()) {
						if (!$('#assist_login_name').val()) loginFormErrorElm.append("Name is required.<br/>");
						if (!$('#assist_login_phone').val()) loginFormErrorElm.append("Phone number is required.<br/>");
						if (assistLoginEmailElm.val() && !validateEmail(assistLoginEmailElm.val())) loginFormErrorElm
								.append("Please enter a valid email.<br/>");
				}
		}
}

function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
}

function validateFormLoginAssist(type) {
		if (type === "submit") {
				const email = $('#assist-login-email').val();
				if ($("#assist-login-form").find("#assist_login_otp").length > 0) {
						return !!($('#assist_login_phone').val() && $('#assist_login_name').val() && $('#assist_login_otp').val());
				} else return !!($('#assist_login_phone').val() && $('#assist_login_name').val() && (!email || validateEmail(email)));
		} else if (type === "resend") return !!($('#assist_login_phone').val() && $('#assist_login_name').val());
		else return false;
}


function AssistPhoneLogin(background = false, recaptcha_token = null) {

		// const Assist_ISD_CODE = $('#assist_countryCode .current-isdcode').text().trim().replace('+', '');
		const Assist_ISD_CODE = $('#assist-login-form .iti__selected-dial-code').text().trim().replace('+', '');
		let user_name = $('#assist_login_name').val();
		let AssistMobNo = $('#assist_login_phone').val().replace(/\s+/g, '');

	gtag('event', {
		event_category: 'Total Signup',
		event_action: ' user_registration',
		event_label: ' user_registration'
	});

		if (AssistMobNo.split('')[0] === "0") AssistMobNo = AssistMobNo.replace('0', '');

		if (Assist_ISD_CODE === '91' && AssistMobNo.length > 10) {
				if (AssistMobNo.slice(0, 2) === '91') AssistMobNo = AssistMobNo.replace('91', '');
		}

		let phone = Assist_ISD_CODE === '91' ? AssistMobNo : `${Assist_ISD_CODE}-${AssistMobNo}`;

		let rndId = Cookies.get("userRandomIdentifier");

		const params = {
				name: user_name, identifier: phone, role: 'parent', email: $('#assist-login-email').val(),
				g_recaptcha_response: recaptcha_token,
				randomId: rndId
		};

		showLoginBtnSpin();

		$.post(`${API_BASE_URL}auth/mobile`, params).always(function (res) {
				let response = res.responseJSON || res;
				if (DEBUG) console.log('login', response);
				let otpElmAssist = $(`
																<label for="">One Time Password</label>
<div id="otp-container" class="d-flex align-items-center">
<div class="otp-input-wrapper d-flex justify-content-start digit-group">
<input id="assist_login_otp" name="login_otp" class="form-control" type="hidden"
																			 placeholder="Enter OTP" >
<input class="otp-input form-control" type="number" data-tabsequence="1" placeholder="X" maxlength="1">
<input class="otp-input form-control" type="number" data-tabsequence="2" placeholder="X" maxlength="1">
<input class="otp-input form-control" type="number" data-tabsequence="3" placeholder="X" maxlength="1">
<input class="otp-input form-control" type="number" data-tabsequence="4" placeholder="X" maxlength="1">
</div>
<a href="javascript:void()" class="resend-otp-btn resend_otp" onclick="resendOtp(event)">Resend</a>
</div>
<div class="assistant-error assistant-otp-error error-text"></div>`);

				/*	change login button name	*/
				$('#submitAssitant').text('Get my options'); //if text change please check assistant.js with contains() function
				$('#prev-nav').hide();
				$('#submitAssitant').css('width','100%');


				if (response.code === 'OK') {
						assistHideLoginBtnSpin();
						$('.auth-error').text('');
						localStorage.setItem('user_type', response.data.code);
						if (response.data.code === 'USER_REGISTERED') {
								localStorage.setItem('user_type', response.data.code);
								if ($("#assist-login-form").find("#assist_login_otp").length === 0)
										// otpElmAssist.insertAfter($('#assist-login-form .main_login'));
										$('#assist-login-form .main_login').append(otpElmAssist);
						} else if (response.data.code === 'USER_NEW') {
								if (!background) {
										if ($("#assist-login-form").find("#assist_login_otp").length === 0)
												// otpElmAssist.insertAfter($('#assist-login-form .main_login'));
												$('#assist-login-form .main_login').append(otpElmAssist);
								}
						} else if (DEBUG) console.log('invalid user registration status');
				} else {
						$('.auth-error').text(response.data || 'An unknown error occurred.').show();
						assistHideLoginBtnSpin();
				}
				grecaptcha.reset(grecaptchaWidgetId2);
		});
}

function login(username, password) {
		const params = {identifier: username, password: password, role: 'parent'};
		showLoginBtnSpin();
		$.post(`${API_BASE_URL}auth/signin`, params).always(function (res) {
				let response = res.responseJSON || res;
				if (DEBUG) console.log('login', response);
				if (response.code === 'OK') {
						const token = response.data.jwt;
						new AssistLoginSuccess(token);
						/*	GOOGLE ANALYTICS CODE FOR SUCCESSFULLY LOGGED IN USER	*/
					gtag('event', {
						event_category: 'Login/Signup',
						event_action: 'Login Success',
						event_label: 'login Successfully'
					});
						assistHideLoginBtnSpin();
						// loginSuccessAssist();

				} else if (response.data.code === 'E_USER_NOT_FOUND') {
						$('.error-text').text('Invalid username or Password. Try again!').show();
						assistHideLoginBtnSpin();
				} else {
						$('.error-text').text('An unknown error occurred.').show();
						assistHideLoginBtnSpin();
				}
		});

}

function AssistLoginSuccess(token) {
		/*	clear any previous session or storage	*/

		const payload = jwt_decode(token);
		if (DEBUG) console.log('login', payload);
		localStorage.setItem('user', JSON.stringify(payload.user));
		localStorage.setItem('access_token', token);
		Cookies.set('access_token_parent', token, {domain: DOMAIN_NAME, expires: 30});
		if (window.location.pathname === '/parents/offers') location.reload();

		auth_init();

		if (user.role === 'parent') {
				assist_auth_ui_update();
				assist_updateEventUserId();
				loginSuccessAssist();
		} else {
				$('.auth-error').text('Invalid user type. Please contact our tech. Support').show();
				assist_logout();
		}
}

function assist_logout() {
		/*	clear token remove user from local storage to log user out	*/
		document.getElementById("login-form").reset();
		if (document.getElementById("otp-container")) document.getElementById("otp-container").remove();
		if (document.getElementById("resend-otp-container"))
				document.getElementById("resend-otp-container").remove();

		this.access_token = null;
		this.requestOptions = null;
		user = null;
		localStorage.removeItem('user');
		localStorage.removeItem('access_token');
		Cookies.remove('access_token_parent', {domain: '.edustoke.com'});
		/*	Google Analytics for Logout User	*/
	gtag('event', {
		event_category: 'Login/Signup',
		event_action: 'Logout',
		event_label: 'logout Successfully'
	});

		assistSetLoginData();
		assist_auth_ui_update();

		//todo reset jwt on server side
}

function reset_login_display() {
		formLoginAssist.show();
		$('.error-text').css("display", "none");
		registerForm.hide();
		lostForm.hide();
		return false;
}

function assist_auth_ui_update() {
		$('#profile-page').find('a').attr('href', `${DASHBOARD_HOST}parent/info`);
		$('#login_register_btn').attr('href', `${DASHBOARD_HOST}parent/auth/register`);

		$('#loginModal').modal('hide');
		if (user) {
				$('.loginUserProfileBlock').show();
				$('.loginBtnBlock').hide();
				if (mobileCheck()) $('#shortlistHeaderHeartIcon').show(); /*	heart icon header show	*/
				$('#btn-profile').show();
				$('#OfferMob').show();
				shortListElm.show();
				$('#btn-auth').hide();
				$('#CheckShortlistFixedButton').show();
				if (typeof resetChatLoginUI !== 'undefined') resetChatLoginUI();
		} else {
				$('.loginUserProfileBlock').hide(); /*	login profile icon hide	*/
				$('.loginBtnBlock').show(); /*	login btn block hide	*/
				$('#shortlistHeaderHeartIcon').hide(); /*	heart icon hide	*/
				$('#btn-profile').hide();
				shortListElm.hide();
				$('#OfferMob').hide();
				$('#wishlist-container').hide();
				$('#btn-auth').show();
				$('#CheckShortlistFixedButton').hide();
		}
}

function auth_init() {
		this.access_token = Cookies.get('access_token_parent') || '';
		localStorage.setItem('access_token', this.access_token);
		if (!localStorage.getItem('user') && this.access_token) {
				const payload = jwt_decode(this.access_token);
				localStorage.setItem('user', JSON.stringify(payload.user));
		}
		user = JSON.parse(localStorage.getItem('user'));

		if (!this.access_token) {
				localStorage.removeItem('user');
				user = null;
		}
		/*	set ajax requests header	*/
		$.ajaxSetup({headers: {Authorization: `Bearer ${this.access_token}`}});
		// add authorization header with jwt token
		//let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token });
		//this.requestOptions = new RequestOptions({ headers: headers });

		setRandomUserId();
		assist_updateEventUserId();
		assist_auth_ui_update();

		if (this.access_token) checkTokenExpired(function (status) {
				if (!status) assist_logout();
				else {
						if (DEBUG) console.log('user logged in Successfully');
						goog_report_conversion();
				}
		});
}

function register() {
		let self = this;
		let assistLoginPhone = $('#assist_login_phone');
		// const Assist_ISD_CODE = $('#assist_countryCode .current-isdcode').text().trim().replace('+', '');
		const Assist_ISD_CODE = $('#assist-login-form .iti__selected-dial-code').text().trim().replace('+', '');
		let phone = Assist_ISD_CODE === '91' ? assistLoginPhone.val() : `${Assist_ISD_CODE}-${assistLoginPhone.val()}`;
		phone = phone.replace(/ /g, ''); /*	remove extra space	*/

		let name = $('#register_username').val();
		let password = $('#register_password').val();
		if ($('#register_password_confirm').val() !== password) {
				$('.error-text').text('Password doesn\'t, match try again').show();
				return;
		}
		let otp = $('#register_otp').val();
		let rndId = Cookies.get("userRandomIdentifier");
		const params = {
				identifier: phone,
				role: 'parent',
				verification_key: otp,
				name: name,
				password: password,
				randomId: rndId
		};
		showLoginBtnSpin();
		$.post(`${API_BASE_URL}auth/verify`, params)
				.always(function (res) {
						let response = res.responseJSON || res;
						if (typeof (self.schoolType) === 'undefined') self.schoolType = '';
						if (DEBUG) console.log('login', response);
						if (response.code === 'OK') {
								if (DEBUG) console.log('verified', response);
								new AssistLoginSuccess(response.data.jwt);

							gtag('event', {
								event_category: 'Total Signup',
								event_action: 'user_verified',
								event_label: 'user_verified'
							});

							gtag('event', {
								event_category: 'Assistant',
								event_action: 'Assistant Register Success',
								event_label: 'Assistant Register Successfully',
								value: self.schoolType
							});

								AssistSchoolTypeRegisterAnalytics(self.schoolType);

								assistHideLoginBtnSpin();
								// loginSuccessAssist();

								/*	Register Google Analytics Code	*/

						} else if (response.code === 'E_UNAUTHORIZED') {
								$('.error-text').text('Incorrect OTP').show();
								assistHideLoginBtnSpin();
						} else {
								$('.error-text').text('An unknown error occurred.').show();
								assistHideLoginBtnSpin();
						}
				});
}


function setRandomUserId() {
		if (!user && (!localStorage.getItem('userRandomIdentifier') || !Cookies.get('access_token_parent'))) {
				let rendomUserId = `R_${Math.random().toString(36).slice(2)}.${Math.floor(Math.random() * Date.now())}`;
				let userid = localStorage.getItem('userRandomIdentifier') || rendomUserId;

				if (Cookies.get('access_token_parent')) userid = true;
				
				if(userid.indexOf('R') === -1) userid = rendomUserId;
				
				localStorage.setItem('userRandomIdentifier', userid);
				Cookies.set('userRandomIdentifier', userid, {domain: DOMAIN_NAME, expires: 15});
		}
}

$(function () {
		/*	GOOGLE ANALYTICS CODE FOR FB AND GOOGLE	*/

		const FB_LOGIN_URL = `${API_BASE_URL}auth/facebook`;
		const GOOGLE_LOGIN_URL = `${API_BASE_URL}auth/google`;

		/*	Facebook Login Button Google Analytics Start	*/
		$('a.btn.btn-facebook.btn-block').on('click', function (e) {
				e.preventDefault();
			gtag('event', {
				event_category: 'Login/Signup',
				event_action: 'Facebook',
				event_label: 'Facebook Button Clicked'
			});
				setTimeout(() => window.location = FB_LOGIN_URL, 1000);
		});

		/*	Google Login Button Google Analytics Start	*/
		$('a.btn.btn-google.btn-block').on('click', function (e) {
				e.preventDefault();
			gtag('event', {
				event_category: 'Login/Signup',
				event_action: 'Google',
				event_label: 'Google Button Clicked'
			});
				setTimeout(() => window.location = GOOGLE_LOGIN_URL, 1000);
		});

		/*	Header Login Button Clicked Google Analytics	*/
		$('#btn-auth').on('click', function () {
				$('.btn-google').show();
			gtag('event', {
				event_category: 'Login/Signup',
				event_action: 'Header Login',
				event_label: 'Header Login Clicked'
			});
		});


		/*	forget password Button Clicked Google Analytics	*/
		$('#forgot_password').on('click', function (e) {
				if (DEBUG) console.log('forgot pass clicked');
				e.preventDefault();
			gtag('event', {
				event_category: 'Login/Signup',
				event_action: 'Reset Password',
				event_label: 'Reset Password Clicked'
			});

				setTimeout(() => window.location = `${DASHBOARD_HOST}parent/auth/reset`, 1000)
		});
});

shortListElm.on('click', function () {
	gtag('event', {
		event_category: 'Wishlist',
		event_action: 'shortlist button clicked',
		event_label: 'shortlist button clicked'
	});
});

function getSearchParams(k) {
		const p = {};
		location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (s, k, v) => p[k] = v);
		return k ? p[k] : p;
}

function assist_updateEventUserId() {
		if (!Cookies.get('userRandomIdentifier') || !user) return;
		$.ajax({
				type: "POST",
				url: `${HOST}/events/update_event_userId.php`,
				cache: false,
				data: {random_userId: Cookies.get('userRandomIdentifier'), client_ip: getSearchParams('client_ip')},
				headers: {
						'Cache-Control': 'no-cache, no-store, must-revalidate',
						'Pragma': 'no-cache',
						'Expires': '0'
				},
				success: (response) => {
						if (DEBUG) console.log(response);
				}
		});
}

function AssistSchoolTypeRegisterAnalytics(schoolType) {
		if (schoolType === 1) {
			gtag('event', {
				event_category: 'Assistant',
				event_action: 'Assistant Day School Register Success',
				event_label: 'Assistant Day School Register Successfully'
			});
		} else if (schoolType === 2) {
			gtag('event', {
				event_category: 'Assistant',
				event_action: 'Assistant Play School Register Success',
				event_label: 'Assistant Play School Register Successfully'
			});
		} else if (schoolType === 3 || schoolType === 4) {
			gtag('event', {
				event_category: 'Assistant',
				event_action: 'Assistant Boarding School Register Success',
				event_label: 'Assistant Boarding School Register Successfully'
			});
		}
}

function AssistVerifyUser() {
		let self = this;
		// const ISD_CODE = $('#assist_countryCode .current-isdcode').text().trim().replace('+', '');
		const ISD_CODE = $('#assist-login-form .iti__selected-dial-code').text().trim().replace('+', '');
		let AssistMobNo = $('#assist_login_phone').val().replace(/ /g, '');
		if (AssistMobNo.split('')[0] === "0") AssistMobNo = AssistMobNo.replace('0', '');

		if (ISD_CODE === '91' && AssistMobNo.length > 10) {
				if (AssistMobNo.slice(0, 2) === '91') AssistMobNo = AssistMobNo.replace('91', '');
		}
		let phone = ISD_CODE === '91' ? AssistMobNo : ISD_CODE + "-" + AssistMobNo;

		let name = $('#assist_login_name').val();

		let otp = $('#assist_login_otp').val();

		let rndId = Cookies.get("userRandomIdentifier");

		const params = {
				identifier: phone,
				role: 'parent',
				verification_key: otp,
				name: name,
				assistant: true,
				randomId: rndId
		};
		showLoginBtnSpin();
		$.post(`${API_BASE_URL}auth/verify`, params)
				.always(function (res) {
						let response = res.responseJSON || res;
						if (DEBUG) console.log('login', response);
						if (response.code === 'OK') {
								if (typeof (self.schoolType) === 'undefined') self.schoolType = '';
								if (DEBUG) console.log('verified', response);
								new AssistLoginSuccess(response.data.jwt);
								if (pageType && pageType === 'DETAILS-PAGE') sendVadNotificationProcessing();
							gtag('event', {
								event_category: 'Total Signup',
								event_action: 'user_verified',
								event_label: 'user_verified'
							});

							gtag('event', {
								event_category: 'Assistant',
								event_action: 'Assistant Register Success',
								event_label: 'Assistant Register Successfully',
								value: self.schoolType
							});

								AssistSchoolTypeRegisterAnalytics(self.schoolType);
								assistHideLoginBtnSpin();
								loginSuccessAssist();
								AssistFinish();
								/*	Register Google Analytics Code	*/

						} else if (response.code === 'E_UNAUTHORIZED') {
								$('.error-text').text('Incorrect OTP').show();
								assistHideLoginBtnSpin();
						} else {
								$('.error-text').text('An unknown error occurred.').show();
								assistHideLoginBtnSpin();
						}
				});
}

$(document).ready(function () {
		assistSetLoginData();


		formLoginAssist.off().on('submit', function (event) {
				event.preventDefault();

				// let loginFormErrorElm = $(".login-form-error");
				let assistLoginEmailElm = $("#assist-login-email");

				// loginFormErrorElm.empty();
				$('.assistant-name-error').empty();
				$('.assistant-phone-error').empty();
				$('.assistant-otp-error').empty();
				$('.assistant-email-error').empty();
				$('#assist_login_name').css('border-color','#9b9b9b');
				$('#assist_login_phone').css('border-color','#9b9b9b');
				assistLoginEmailElm.css('border-color','#9b9b9b');

				location_type = 'PREF';

				if (validateFormLoginAssist('submit')) {
						showLoginBtnSpin();

						if (formLoginAssist.find("#assist_login_otp").length === 0) grecaptcha.execute(grecaptchaWidgetId2);
						else if (formLoginAssist.find("#assist_login_otp").length > 0) new AssistVerifyUser();

				} else {


								if (!$('#assist_login_name').val()) {
										$('.assistant-name-error').append("Name is required.");
										$('#assist_login_name').css('border-color','red');
								}
								if (!$('#assist_login_phone').val()) {
										$('.assistant-phone-error').append("Phone number is required.<br/>");
										$('#assist_login_phone').css('border-color','red');
								}

								if (formLoginAssist.find("#assist_login_otp").length > 0 && !$('#assist_login_otp').val()) {
										$('.assistant-otp-error').append("OTP is required.<br/>");
								}

								if ((assistLoginEmailElm.val() && !validateEmail(assistLoginEmailElm.val())) || !assistLoginEmailElm.val()) {
										$('.assistant-email-error').append("Please enter a valid email.<br/>");
										assistLoginEmailElm.css('border-color','red');
								}
				}
		});
})


function assistSetLoginData() {
		if (localStorage.getItem('user_login_data')) {
				let user_login_data = JSON.parse(localStorage.getItem('user_login_data'));
				let user_phn = user_login_data.phone;
				$('#assist_login_name').val(user_login_data.name);
				$('#assist_login_phone').val(user_phn.slice(user_phn.lastIndexOf('-') + 1, user_phn.length));
				// $(`#country-code li[data-isdcode="+${user_phn.slice(0, user_phn.indexOf('-'))}"] a`).click();
		}
}

