/*	Autocomplete location	*/
var pref_loc_data1 = {};
var assistPlaceData = {};
var location_type1 = "CURRENT";
let justSubmitted1 = false;
/*let geocoder1 = new google.maps.Geocoder;*/
let initialLocation1 = {lat1: latitude, lng1: longitude, city1: city_name};
let school1;
let searchedCity1;

let inputPref1 = document.getElementById('preferred-locality');
let autocompletePref1 = new CustomAutoCompleteMaps(inputPref1);

$(function () {
		let schoolType = $('input[name="school_type"][checked="checked"]').val();
		if (typeof schoolType === 'undefined') {
				let btnNextElm = $('.btn-next');
				btnNextElm.attr('disabled', 'disabled');
				btnNextElm.css('cursor', 'not-allowed');
		}

		//assistant locality input error remove
		/*if($("#prefsearchTextField1").length){
				let locationInput = $("#prefsearchTextField1");
				locationInput.on('input', function(e){
					if($(this).hasClass('border-danger')){
						$(this).removeClass('border-danger');
					}
				});
		}*/

});

$("#assistProgress a").click(function (e) {
		let clicked = $(this).attr('href');

		if ($(`a[href="${clicked}"]`).find('.icon-circle.checked').length === 1) {

				const btnNextElm = $('.btn-next');

				btnNextElm.removeAttr('disabled');
				btnNextElm.css('cursor', 'pointer');

		} else {
				e.preventDefault();
				return false;
		}

});

$('.assistFilter .assist-tag').click(function () {
		const clickedId = $(this).parent().parent().attr('id');
		$(this).toggleClass("active-assist"); /*	Filter selection color changed	*/
		setTimeout(function () {

				if (["feeFilter", "boardFilter", "regionFilter"].includes(clickedId)) {
						feeRequired();
						RegionRequired();
						return false;
				} else $('.NextInline .btn-next').click();

		}, 700);


		ageOneSelect();
		gradeOneSelect();
		setTimeout(function () {
				gradeText();
				agetext();
		}, 300);
		feestext();
		boardstext();
		assistRegionText();

});

function feeRequired() {

		let activeWizardTab = $('.wizard-card').bootstrapWizard('currentIndex');
		const btnNextElm = $('.btn-next');

		if (activeWizardTab === 2) {
				if ($('#feeFilters span.active-assist').length === 0) {
						btnNextElm.attr('disabled', 'disabled');
						btnNextElm.css('cursor', 'not-allowed');
						return false;
				} else {
						btnNextElm.removeAttr('disabled');
						btnNextElm.css('cursor', 'pointer');
				}
		} else {
				btnNextElm.removeAttr('disabled');
				btnNextElm.css('cursor', 'pointer');
		}

}

function NextEnable() {
		const btnNextElm = $('.btn-next');

		btnNextElm.removeAttr('disabled');
		btnNextElm.css('cursor', 'pointer');

}

$(document).ready(function () {
		school1 = {slat1: initialLocation1.lat1, slon1: initialLocation1.lng1};

		CustomAutoCompleteMaps.addListener(autocompletePref1, 'place_changed', () => {
				let place1 = autocompletePref1.getPlace();
				assistPlaceData = place1;
				$('#preferred-latitude').val(place1.geometry.location.lat());
				$('#preferred-longitude').val(place1.geometry.location.lng());
				placeChangedDebugApiCallAnalytics();
		});
});

/* DISABLE ENTER KEYBOARD  SELECT in PREF LOCATION */
$('#preferred-location-form').on('keyup keypress', function (e) {
		const keyCode = e.keyCode || e.which;
		if (keyCode === 13) {
				e.preventDefault();
				return false;
		}
});

$('#notification').click(function () {
		if ($('#notification').val() === '0') {
				$('#notification').val('1');
		} else if ($('#notification').val() === '1') {
				$('#notification').val('0');
		}
});

function ageOneSelect() {
		$('#ageFilter span.assist-tag').click(function () {
				$(this).addClass('active-assist');
				$(this).siblings().removeClass('active-assist');
		})
}

function gradeOneSelect() {
		$('#filter_grade span.assist-tag').click(function () {
				$(this).addClass('active-assist');
				$(this).siblings().removeClass('active-assist');
		});
}


function setTextSchool(type) {
		type = parseInt(type);
		if (type === 1) {
				$('.school-type-text').text('Day School');
		} else if (type === 2) {
				$('.school-type-text').text('Play School');
		} else if (type === 5) {
				$('.school-type-text').text('PU School');
		} else {
				$('.school-type-text').text('Boarding School');
		}
}


function assistRegionText() {
		let regionstext = [];
		$('#regionFilter').find('span.assist-tag.active-assist').each(function () {
				regionstext.push($(this).text());
		});
		let assistRegionTextStr = regionstext.join(',');
		$('.region-text').text(assistRegionTextStr);

}

function gradeText() {
		let gradeTextStr = $('#gradeFilter').find('span.assist-tag.active-assist').text();
		$('.grade-text').text(gradeTextStr);

}

function feestext() {
		let feetext = [];
		$('#feeFilter').find('span.assist-tag.active-assist').each(function () {
				feetext.push($(this).text());
		});

		let assistfeetext = feetext.join(' and ');
		$('.fees-text').text(assistfeetext);

}

function boardstext() {
		let boardstext = [];
		$('#filter_board').find('span.assist-tag.active-assist').each(function () {
				boardstext.push($(this).text());
		});
		let assistboardtext = boardstext.join(',');
		$('.board-text').text(assistboardtext);
}

function agetext() {
		let agetext = $('#ageFilter').find('span.assist-tag.active-assist').text();
		$('.age-text').text(agetext);
}

function typetexthideshow(type) {
		if (type == 1 || type == 3) {
				$('#type5').hide();
				$('#type13').show();
				$('#type2').hide();
				$('#type5').hide();
				$('.fee_day_heading').show();
				$('.fee_pre_heading').hide();
		}
		if (type == 2) {
				$('#type13').hide();
				$('#type2').show();
				$('.fee_day_heading').hide();
				$('.fee_pre_heading').show();
		}
		if (type == 5) {
				$('#type13').hide();
				$('#type2').hide();
				$('#board_123').hide();
				$('#type5').show();
				$('#board_5').show();
				$('.fee_day_heading').show();
				$('.fee_pre_heading').hide();
		}
}

function lasttexthideshow(type) {
		if (type == 1) {

				$('#lasttext1').show();
				$('#lasttext2').hide();
				$('#lasttext3').hide();
				$('#lasttext5').hide();
		}
		if (type == 2) {
				$('#lasttext1').hide();
				$('#lasttext2').show();
				$('#lasttext3').hide();
				$('#lasttext5').hide();
		}
		if (type == 3) {
				$('#lasttext1').hide();
				$('#lasttext2').hide();
				$('#lasttext3').show();
				$('#lasttext5').hide();
		}
		if (type == 5) {
				$('#lasttext1').hide();
				$('#lasttext2').hide();
				$('#lasttext3').hide();
				$('#lasttext5').show();
		}
}

let typeSchool = null;

$(".choice").click(function () {
		$('.icon-circle').removeClass('checked');
		setTimeout(function () {
				$('a .icon-circle').parent().css('color', '#696969');
				$('a .icon-circle.checked').parent().css('color', 'white');
		}, 200);

		$('.btn-next').removeAttr('disabled');
		$('.btn-next').css('cursor', 'pointer');
		setTimeout(
				function () {
						var assisttypeSchool = $('input[name="school_type"][checked="checked"]').val();
						setTextSchool(assisttypeSchool);
						typetexthideshow(assisttypeSchool);
						lasttexthideshow(assisttypeSchool);
						FilterHideShow(assisttypeSchool);
				}, 100);

		setTimeout(
				function () {
						/*$('.btn-next').click();*/
						$('.NextInline .btn-next').click();
				}, 700
		)
});


function FilterHideShow(assisttypeSchool) {
		if (assisttypeSchool == 4) {
				assisttypeSchool = 3;
		}

		/* Fee changes Start */

		if (assisttypeSchool == 1) {

				$('ul.nav.nav-pills').find('li').css('width', '20%');
				$('.wizard-card').bootstrapWizard('display', 1);
				$('.wizard-card').bootstrapWizard('display', 4);
				$('.wizard-card').bootstrapWizard('hide', 2);
				$('.wizard-card').bootstrapWizard('hide', 5);

				$('#feeFilter .assist-pre-sc-tag').hide();
				$('#feeFilter .assist-pre-sc-tag').removeClass('active-assist');
				$('#feeFilter .assist-day-sc-tag').show();
				$('#feeFilter .assist-show-resi-sc-tag').hide();
				$('#feeFilter .assist-show-resi-sc-tag').removeClass('active-assist');
				$('#preferred-location-form').show();
				$('#notifyAssist').show();
				$('#gender').hide();
		}

		if (assisttypeSchool == 5) {

				$('ul.nav.nav-pills').find('li').css('width', '25%');
				$('.wizard-card').bootstrapWizard('display', 1);
				$('.wizard-card').bootstrapWizard('display', 4);
				$('.wizard-card').bootstrapWizard('hide', 2);
				$('.wizard-card').bootstrapWizard('hide', 5);

				$('#feeFilter .assist-pre-sc-tag').hide();
				$('#feeFilter .assist-pre-sc-tag').removeClass('active-assist');
				$('#feeFilter .assist-day-sc-tag').show();
				$('#feeFilter .assist-show-resi-sc-tag').hide();
				$('#feeFilter .assist-show-resi-sc-tag').removeClass('active-assist');
				$('#preferred-location-form').show();
				$('#notifyAssist').show();
				$('#gender').hide();
				$('#grade-list').hide();
		}

		if (assisttypeSchool == 2) {

				$('ul.nav.nav-pills').find('li').css('width', '25%');
				$('.wizard-card').bootstrapWizard('display', 2);
				$('.wizard-card').bootstrapWizard('hide', 1);
				$('.wizard-card').bootstrapWizard('hide', 4);
				$('.wizard-card').bootstrapWizard('hide', 5);

				$('#feeFilter .assist-pre-sc-tag').show();
				$('#feeFilter .assist-day-sc-tag').hide();
				$('#feeFilter .assist-day-sc-tag').removeClass('active-assist');
				$('#feeFilter .assist-show-resi-sc-tag').hide();
				$('#feeFilter .assist-show-resi-sc-tag').removeClass('active-assist');
				$('#preferred-location-form').show();
				$('#notifyAssist').hide();
				$('#gender').hide();
		}
		if (assisttypeSchool == 3) {
				$('ul.nav.nav-pills').find('li').css('width', '16.66%');
				//$('.nav-pills > li.active a').addClass('right9px');
				$('.wizard-card').bootstrapWizard('display', 1);
				$('.wizard-card').bootstrapWizard('display', 4);
				$('.wizard-card').bootstrapWizard('display', 5);
				$('.wizard-card').bootstrapWizard('display', 6);
				$('.wizard-card').bootstrapWizard('hide', 2);
				$('#feeFilter .assist-pre-sc-tag').hide();
				$('#feeFilter .assist-day-sc-tag').hide();
				$('#feeFilter .assist-pre-sc-tag').removeClass('active-assist');
				$('#feeFilter .assist-day-sc-tag').removeClass('active-assist');
				$('#feeFilter .assist-show-resi-sc-tag').show();
				$('#preferred-location-form').hide();
				$('#notifyAssist').show();
				$('#gender').show();

		}

		/*Fee changes end */

}


/* SCHOOL DETAIL PAGE CODE START */
$(function () {


				function SchoolTypeCheck(type) {
						if (DEBUG) console.log('SchoolTypeCheck detail pgae');
						if (type == 4) {
								type = 3;
						}

						$('input[name="school_type"][value=' + type + ']').click();
				}

				function setAddress($city, $lat, $lng) {
						$('#preferred-locality').val($city);
						$('#preferred-latitude').val($lat);
						$('#preferred-longitude').val($lng);
				}


				if (typeof pageType === 'undefined') {  // Checking page type Undefined conditions
						pageType = '';
				}

				if (pageType == 'DETAILS-PAGE') {
						setTimeout(function () {
								SchoolTypeCheck(schoolType);
						}, 1000);


						var typeboards = boardAssist.split(',');
						var lengthboards = typeboards.length;
						if (lengthboards == 1) {
								BoardSelect(boardAssist);
						} else if (lengthboards == 2) {
								BoardSelect(typeboards[0]);
								BoardSelect(typeboards[1]);
						} else if (lengthboards == 3) {
								BoardSelect(typeboards[0]);
								BoardSelect(typeboards[1]);
								BoardSelect(typeboards[2]);
						}

						BoardSelect(boardAssist);
						AssistFeeRange(schoolType, fees);
						RegionFilter(regionText);
						setAddress(schoolAddressCity, lat, lng); //save address with school city

						// Page Load Region Set Automatic
						function RegionFilter(type) {
								if (type == "north") {
										$('span[data-id="region-1"]').addClass('active-assist');

								} else if (type == "east") {

										$('span[data-id="region-2"]').addClass('active-assist');

								} else if (type == "west") {
										$('span[data-id="region-4"]').addClass('active-assist');

								} else if (type == "south") {
										$('span[data-id="region-3"]').addClass('active-assist');

								} else if (type == "central") {
										$('span[data-id="region-5"]').addClass('active-assist');
								}
						}

						// On Load Board Select in detail page
						function BoardSelect(type) {

								if (type == "CBSE") {
										$('span[data-id="board-1"]').addClass('active-assist');

								} else if (type == "ICSE") {
										$('span[data-id="board-2"]').addClass('active-assist');
								} else if (type == "IB") {
										$('span[data-id="board-3"]').addClass('active-assist');
								} else if (type == "IGCSE") {
										$('span[data-id="board-6"]').addClass('active-assist');
								} else if (type == "State Board") {
										$('span[data-id="board-5"]').addClass('active-assist');
								} else if (type == "Other boards") {
										$('span[data-id="board-4"]').addClass('active-assist');
								}
						}

						// On Load Fee range select
						function AssistFeeRange(schoolType, fee) {
								if (schoolType == 4) {
										schoolType = 3;
								}
								if (schoolType == 1) {
										if (fee < 30000) {
												$('span[data-filter="500-30000"]').addClass('active-assist');
										} else if (fee >= 30000 && fee < 50000) {
												$('span[data-filter="30000-50000"]').addClass('active-assist');
										} else if (fee >= 50000 && fee < 70000) {
												$('span[data-filter="50000-70000"]').addClass('active-assist');
										} else if (fee >= 70000 && fee < 100000) {
												$('span[data-filter="70000-100000"]').addClass('active-assist');
										} else if (fee >= 100000 && fee < 200000) {
												$('span[data-filter="100000-200000"]').addClass('active-assist');
										} else if (fee >= 200000) {
												$('span[data-filter="200000-1000000"]').addClass('active-assist');
										}

								} else if (schoolType == 5) {
										if (fee < 30000) {
												$('span[data-filter="500-30000"]').addClass('active-assist');
										} else if (fee >= 30000 && fee < 50000) {
												$('span[data-filter="30000-50000"]').addClass('active-assist');
										} else if (fee >= 50000 && fee < 70000) {
												$('span[data-filter="50000-70000"]').addClass('active-assist');
										} else if (fee >= 70000 && fee < 100000) {
												$('span[data-filter="70000-100000"]').addClass('active-assist');
										} else if (fee >= 100000 && fee < 200000) {
												$('span[data-filter="100000-200000"]').addClass('active-assist');
										} else if (fee >= 200000) {
												$('span[data-filter="200000-1000000"]').addClass('active-assist');
										}

								} else if (schoolType == 2) {
										fee = fee / 12;
										if (fee < 2500) {
												$('span[data-filter="500-2500"]').addClass('active-assist');
										} else if (fee >= 2500 && fee < 5000) {
												$('span[data-filter="2500-5000"]').addClass('active-assist');

										} else if (fee >= 5000 && fee < 8000) {
												$('span[data-filter="5000-8000"]').addClass('active-assist');
										} else if (fee >= 8000) {
												$('span[data-filter="8000-100000"]').addClass('active-assist');
										}
								} else if (schoolType === 3) {
										if (fee >= 500 && fee < 300000) {
												$('span[data-filter="500-300000"]').addClass('active-assist');
										} else if (fee >= 300000 && fee < 500000) {
												$('span[data-filter="300000-500000"]').addClass('active-assist');
										} else if (fee >= 500000 && fee < 700000) {
												$('span[data-filter="500000-700000"]').addClass('active-assist');
										} else if (fee >= 700000 && fee < 1000000) {
												$('span[data-filter="700000-1000000"]').addClass('active-assist');
										} else if (fee >= 1000000) {
												$('span[data-filter="1000000-2000000"]').addClass('active-assist');
										}
								}
						}
				}
		}
);

/*SCHOOL DETAIL PAGE CODE END*/


/*SCHOOL SEARCH PAGE CODE START */
$(function () {

		function SchoolTypeCheck(type) {
				if (DEBUG) console.log('SchoolTypeCheck search page', {type});
				if (type == 4) {
						type = 3;
				}

				$('input[name="school_type"][value=' + type + ']').click();
				// $('.btn-next').click();
		}

		function setAddress($city, $lat, $lng) {
				$('#preferred-locality').val($city);
				$('#preferred-latitude').val($lat);
				$('#preferred-longitude').val($lng);
		}


		if (pageType == "search") {

				setTimeout(function () {
						SchoolTypeCheck(schooltype); // school type automatic select
				}, 1000);

				let address = $('#formatted_address').val();
				let lat = $('#cityLat').val();
				let lng = $('#cityLng').val();

				setAddress(address, lat, lng);
		}
});


/*Query Fired on Submit Search page Assistant */

function DayORBoardingSchoolSearchQuery(PlayLoc, PlaycityLat, PlaycityLng, type, gender) {
		sessionStorage.removeItem('removeSearchData');
		let searchQueryPath = '';
		let genders = (gender.length && type === 3) ? gender.replace(/coed-/g, '').split(',') : '';
		let coed = [];
		let cityText = '';
		let city_name = '';
		let state_name = '';
		if (genders.length) {
				genders.map((coedType, i) => {
						let coedId = coedType.replace('coed', '');
						coed.push(getCoedTypeName(coedId));
				});
		}

		if (coed.length) searchQueryPath += coed.join('-and-');

		let boards = [];
		$('#AssistantModal .assistant-board-wrapper .assistant-tag.selected').each(function () {
				let boardName = getBoardName($(this).attr('data-filter'))
				if (boardName.length) boards.push(boardName);
		});

		if (boards.length > 0) searchQueryPath += ((coed.length) ? '-' : '') + boards.join("-and-") + "-schools";

		let regions = [];
		$('#AssistantModal .assistant-region-wrapper .assistant-tag.selected').each(function () {
				let regionStr = $(this).attr('data-filter');
				regions.push(regionStr.toLowerCase());
		});

		if (regions.length) searchQueryPath += ((coed.length || boards.length) ? "-in-" : '') + regions.join("-and-") + "-region";

		let placeBaseCode = encode_decode({
				latitude: PlaycityLat,
				longitude: PlaycityLng
		});

		let address = PlayLoc.length ? slugify(PlayLoc) : '';

		if(type !== 3){ // fallback when user not search in assistant
				if(!assistPlaceData.hasOwnProperty('address_components')){
						if(!!localStorage.getItem('searchSessionDataForAssist') && !!PlayLoc){
								let searchSessionData = JSON.parse(localStorage.getItem('searchSessionDataForAssist'));
								assistPlaceData = searchSessionData.searchPlaceLocationData;
						}else{ // fallback for old assistant user

								if(!!assistantUserData && !!PlayLoc){
										sessionStorage.setItem('removeSearchData', '1');
										city_name = '';
										PlaycityLat = '';
										PlaycityLng = '';
										if(!!assistantUserData.lead_location) address = slugify(assistantUserData.lead_location);
										if(!!assistantUserData.lat) PlaycityLat = assistantUserData.lat;
										if(!!assistantUserData.lng) PlaycityLng = assistantUserData.lng;

										if(!!getCityStateDataByAddressData.hasOwnProperty('data')){
												if(getCityStateDataByAddressData.data.cities.length){
														city_name = getCityStateDataByAddressData.data.cities[0];
												}

												if(getCityStateDataByAddressData.data.state.length){
														state_name = getCityStateDataByAddressData.data.state[0];
												}
										}

										if(!!assistantUserData.lat && !!assistantUserData.lng){
												placeBaseCode = encode_decode({
														latitude: PlaycityLat,
														longitude: PlaycityLng
												});
										}
								}else{ // when user didn't type location
										// if(!!city_name) cityText = slugify(city_name);
										sessionStorage.setItem('removeSearchData', '1');
										city_name = '';
										PlaycityLat = '';
										PlaycityLng = '';
										if(!!latitude ) PlaycityLat = latitude;
										if(!!longitude) PlaycityLng = longitude;

										if(!!latitude && !!longitude){
												placeBaseCode = encode_decode({
														latitude: PlaycityLat,
														longitude: PlaycityLng
												});
										}
								}
						}
				}
		}

		let formatted_address = address;

		if (!address) address = !!cityName ? slugify(cityName) : '';
		if (PlaycityLat && PlaycityLng) address += '-' + placeBaseCode.data.latlng;
		if (address.length) address += '/';

		if (type === 3) address = ''; //remove address in boarding state

		let dayfees = [];
		$('#AssistantModal .assistant-fees-wrapper .assistant-tag.selected').each(function () {
				dayfees.push($(this).attr('data-id'));
		});

		let Dayfee = '?Tags[]=';
		if (dayfees.length) {
				Dayfee += dayfees.length > 0 ? dayfees.join('&Tags[]=') : dayfees;
		} else {
				Dayfee = '';
		}

		/* search necessary data */
		let placeLevel = '';
		let localityObj = [];
		let administrativeAreaLevel1 = [];
		let administrativeAreaLevel2 = [];
		let city_2_name = '';
		let filteredFormattedAddressData = '';
		let filteredFormattedAddressDataStr = '';
		let locality = '';
		if (type !== 3) {
				if (!assistPlaceData.hasOwnProperty('address_components')) {
						assistPlaceData = JSON.parse(localStorage.getItem('searchSessionDataForAssist'));
				}

				if (!!assistPlaceData && assistPlaceData.hasOwnProperty('address_components')) {
						filteredFormattedAddressData = filterGoogleMapApiResponse(assistPlaceData);
						filteredFormattedAddressDataStr = filteredFormattedAddressDataSlug(assistPlaceData);

						localityObj = assistPlaceData.address_components.filter((v, i) => {
								if (v.types[0] === 'locality') return v;
						});

						administrativeAreaLevel1 = assistPlaceData.address_components.filter((v, i) => {
								if (v.types[0] === 'administrative_area_level_1') return v;
						});

						administrativeAreaLevel2 = assistPlaceData.address_components.filter((v, i) => {
								if (v.types[0] === 'administrative_area_level_2') return v;
						});
				}

				if (localityObj.length) {
						// city_name = localityObj[0].long_name.toLowerCase();
						// placeLevel = localityObj[0].types[0];
						city_name = filteredFormattedAddressData.city.toLowerCase();
				}

				if(filteredFormattedAddressData.hasOwnProperty('locality')){
						if(!!filteredFormattedAddressData.locality && filteredFormattedAddressData.locality.length){
								placeLevel = localityObj[0].types[0];
								locality = filteredFormattedAddressData.locality[filteredFormattedAddressData.locality.length - 1];
						}
				}

				if (administrativeAreaLevel2.length) {
						city_2_name = slugify(administrativeAreaLevel2[0].long_name.toLowerCase());
						// if (!placeLevel) placeLevel = administrativeAreaLevel2[0].types[0];
				}

				if (!!administrativeAreaLevel1.length) {
						// state_name = slugify(administrativeAreaLevel1[0].long_name);
						state_name = slugify(filteredFormattedAddressData.state);
						if (!placeLevel && !city_name) placeLevel = administrativeAreaLevel1[0].types[0];
				}

				/* check if city name like state name */
				if (!!city_name) {
						let checkCityAsStateExist = checkCityExistsInStates(city_name);
						if (checkCityAsStateExist.length) {
								city_name = "";
								city_2_name = "";
								if (!!administrativeAreaLevel1.length) placeLevel = administrativeAreaLevel1[0].types[0];
						}
				}

				if (pageType !== "SEO") city_2_name = "";

				let searchSessionData = {
						'locality': localityObj,
						'administrativeAreaLevel1': administrativeAreaLevel1,
						'administrativeAreaLevel2': administrativeAreaLevel2,
						'city_name': city_name,
						'city_2_name': city_2_name,
						'state_name': state_name,
						'formattedAddress': formatted_address,
						'latitude': PlaycityLat,
						'longitude': PlaycityLng,
						'placeLevel': placeLevel,
						'searchPlaceLocationData': assistPlaceData
				};

				localStorage.setItem('searchSessionData', JSON.stringify(searchSessionData));
				localStorage.setItem('searchSessionDataForAssist', JSON.stringify(searchSessionData));

				if(!!sessionStorage.getItem('removeSearchData')){
						localStorage.removeItem('searchSessionData')
						localStorage.removeItem('searchSessionDataForAssist');
				}

		}

		if (type === 3) {
				sessionStorage.setItem('homepageSearchSession', true);
		}

		let placeQueryData = '';

		if(!filteredFormattedAddressDataStr){
				if (!!placeLevel) placeQueryData += `&place_lavel=${placeLevel}`;
				if (!!city_name) placeQueryData += `&city_name=${slugify(city_name)}`;
				if(!!locality) placeQueryData += (city_name.length ? '&' : '') + `locality=${slugify(locality)}`;
				if ((city_name !== city_2_name) && city_2_name.length) placeQueryData += `&city_2_name=${slugify(city_2_name)}`;
				if ((!city_name && !city_2_name) && state_name.length) placeQueryData += `&state_name=${slugify(state_name)}`;
		}else{
				placeQueryData+= !!filteredFormattedAddressDataStr ? `&${filteredFormattedAddressDataStr}` : '';
		}

		/**
		 * *********Range Max********
		 *  Day & PU :   30 in case City || 5  in case Locality
		 *  PreSchool : 10 in case City || 5  in case Locality
		 *  Boarding : 100 in case Locality || City Both
		 *  Start
		 * */

		let localityStr = getCleanLocality(formatted_address, city_name, city_2_name, state_name);

		let distanceRangeMax = '';
		if ([1, 5].includes(parseInt(type)) && localityStr) { // update range slider in case of city id
				distanceRangeMax = 5;
		}
		if (!!city_name.length && [1, 5].includes(parseInt(type)) && !localityStr) { // update range slider in case of city id
				distanceRangeMax = 30;
		}

		if ([2].includes(parseInt(type)) && localityStr) { // update range slider in case of city id
				distanceRangeMax = 5;
		}
		if (!!city_name.length && [2].includes(parseInt(type)) && !localityStr) { // update range slider in case of city id
				distanceRangeMax = 10;
		}

		if ([3].includes(parseInt(type)) && localityStr) { // update range slider in case of city id
				distanceRangeMax = 100;
		}
		if (!!city_name.length && [3].includes(parseInt(type))) { // update range slider in case of city id
				distanceRangeMax = 100;
		}

		if (distanceRangeMax && !regions.length) placeQueryData += `&range_max=${distanceRangeMax}`;
		/**
		 * *********Range Max*******
		 * END
		 **/

		let URL = HOST;
		URL += `/search/${getSchoolTypeName(type)}/${address}${searchQueryPath}${Dayfee.length ? Dayfee : ''}${placeQueryData.length ? placeQueryData : ''}`;
		// console.log(`${URL}&sort_vad=1&bp=ch`);
		window.location.href = `${URL}&sort_vad=1&bp=ch`;

}


function PreSchoolSearchQuery(PlayLoc, PlaycityLat, PlaycityLng) {
		let playfees = [];
		$('#feeFilter').find('span.assist-tag.active-assist').each(function () {
				playfees.push($(this).attr('data-id'));
		});

		let Playfee = playfees.join('&Tags[]=');

		let URL = HOST + "/search.php?locality=" + PlayLoc + "&cityLat=" + PlaycityLat + "&cityLng=" + PlaycityLng + "&schooltype=2&Tags[]=" + Playfee;
		window.location.href = URL;
}


/*SCHOOL SEARCH PAGE CODE END */

function assistantSubmitOnLogin() {
		/*if (justSubmitted) return;
		 justSubmitted = true;*/
		showLoaderCoverAssist();

		let data = {
				place_name: $('#preferred-locality').val(),
				location_type: location_type,
				lat: $('#preferred-latitude').val(),
				lng: $('#preferred-longitude').val(),
				label: $('input[name=prefOpt]:checked').val()
		};
		if (DEBUG) console.log('submitting tracking data', data);

		if (data.lat === "" || data.lng === "" || data.place_name === "") {
				//when empty use get ip geolocation lat long constant defined in prefered loaction.php
				data.lat = latitude;
				data.lng = longitude;
				data.place_name = cityName;
		}

		$.ajax({
				type: "POST",
				url: `${HOST}/users/user_track/user_track_insert_or_update.php`, /* users/user_track/user_track_insert.php replaced */
				data: data,
				success: (response) => {
						response = response ? response.toString() : "null";

						if (response.trim() !== "null") {

								//$('#AssistantModal').modal('hide');
								$('#pref_location_error').text('');
								AssistSubmit();
								// getPrefLoc();
						} else {
								$('#pref_location_error').text("please try again failed to set Preferred location.");
						}
						hideLoaderCoverAssist();
				}
		});
}

function submitPrefLoc1() {
		if (window.location.pathname === "/search.php") {
				$('#AssistantModal').modal('hide'); // show modal
				if (location_type === 'PREF') {
						if (!user) {
								$('#loginModal').modal('show');
						}
				}
		} else { // DETAIL PAGE
				$('#AssistantModal').modal('hide');
				if (!user) {
						$('#loginModal').modal('show');
				}


		}
}

function RegionRequired() {

		let TypeSCHOOL = $('input[name="school_type"][checked="checked"]').val();
		if (TypeSCHOOL == 3) {
				let activeTab = $('.wizard-card').bootstrapWizard('currentIndex');


				if (activeTab == 4) {
						if ($('#regionFilter span.active-assist').length === 0) {
								$('.btn-next').attr('disabled', 'disabled');
								$('.btn-next').css('cursor', 'not-allowed');
								return false;
						} else {

								$('.btn-next').removeAttr('disabled');
								$('.btn-next').css('cursor', 'pointer');
						}
				} else {
						let btnNextattr = $('.btn-next').attr('disabled');
						if (btnNextattr == 'disabled') {

						} else {
								$('.btn-next').removeAttr('disabled');
								$('.btn-next').css('cursor', 'pointer');
						}

				}

		}
}


/*function getPrefLoc() {
    if (user) {
        $.ajax({
            type: "GET",
            url: `${HOST}/users/user_track/user_track_get.php`,
            cache: false,
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
            success: (response) => {
                if (response) {
                        $('#pref_location_btn').text('Update Preferred Location');
                        pref_loc_data = JSON.parse(response);
                        $('#preferred-locality').val(pref_loc_data.place_name);
                        location_type = pref_loc_data.location_type;
                        $('#preferred-latitude').val(pref_loc_data.lat);
                        $('#preferred-longitude').val(pref_loc_data.lng);
                        if (pref_loc_data.label) {
                            $('input[name=prefOpt][value=' + pref_loc_data.label + ']').attr('checked', 'checked');
                        }
                        if ($('input[name=prefOpt]:checked').val() === "home") {
                            $('#pref_location_text').html('Preferred location <b class="text-capitalize">' + $('input[name=prefOpt]:checked').val() + '</b>');
                        } else {
                            $('#pref_location_text').text('Preferred location ' + pref_loc_data.place_name);
                        }
                        loadWishlist();
                    } else {
                        refresh_initial_location();
                        // checkPageName();
                        $('#preferred-locality').val(initialLocation.city);
                        $('#preferred-latitude').val(initialLocation.lat);
                        $('#preferred-longitude').val(initialLocation.lng);
//                        $('#AssistantModal').modal('show');

                    }
            }
        });
    }
}*/

/*
function getAddressFromUrl() {
    let addressStr = window.location.search.replace("?", "").split("&");
    addressStr.forEach((value) => {
        let tmpValues = value.split("=");
        if (tmpValues[0] === "cityLat") {
            initialLocation.lat = tmpValues[1];
        }
        if (tmpValues[0] === "cityLng") {
            initialLocation.lng = tmpValues[1];
        }
        if (tmpValues[0] === "locality") {
            searchedCity = tmpValues[1].replace(/%2C[+]/g, ", ");
            initialLocation.city = getLocationName(initialLocation);
        }
    });
    return initialLocation;
}

function set_jq_dflt() {
    jQuery.validator.setDefaults({
        debug: true,
        success: "valid"
    });
}

var jquery_validatea = false;
var dditional_methods = false;
var additional_methods = document.createElement('script');

jquery_validate.onload = (() => {
    jquery_validatea = true;
    additional_methods.type = 'text/javascript';
    additional_methods.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.16.0/additional-methods.min.js';
    body.appendChild(additional_methods);
    if (jquery_validatea && dditional_methods) {
        set_jq_dflt();
    }

});
additional_methods.onload = (() => {
    dditional_methods = true;
    if (jquery_validatea && dditional_methods) {
        set_jq_dflt();
    }
});
*/

/*

function refresh_initial_location() {
    if (pref_loc_data.location_type && pref_loc_data.location_type === 'PREF') {
        initialLocation = {
            lat: pref_loc_data.lat,
            lng: pref_loc_data.lng,
            city: pref_loc_data.place_name
        }
    } else if (window.location.pathname === "/search.php") {
        initialLocation = getAddressFromUrl()
    } else if (window.location.pathname === "/") {
        if (!initialLocation.city) {
            initialLocation.city = getLocationName(initialLocation);
        }
    } else if (school) {
        initialLocation = {
            lat: school.slat1,
            lng: school.slon1,
            // city: school.locality
        };
        if (!initialLocation.city) {
            initialLocation.city = getLocationName(initialLocation);
        }
    }
}
*/

$('#AssistantModal').on('shown.bs.modal', () => {
		refresh_initial_location();
});

$('#AssistantModal').on('hidden.bs.modal', () => {
		if (pref_loc_data.location_type && pref_loc_data.location_type === 'PREF') return;
		let place_name = $('#preferred-locality').val();
		let lat = $('#preferred-latitude').val();
		let lng = $('#preferred-longitude').val();
		$('.button_1O').show();


		/*if (window.location.pathname === "/search.php" || window.location.pathname === "/" || school) {
				$('#preferred-locality').val(initialLocation.city);
				location_type = location_type;
				$('#preferred-latitude').val(initialLocation.lat);
				$('#preferred-longitude').val(initialLocation.lng);
				submitPrefLoc();
		}*/
});


/*
function getLocationName(initiLocation) {
    let latlng = {lat: parseFloat(initiLocation.lat), lng: parseFloat(initiLocation.lng)};
    geocoder.geocode({'location': latlng}, function (results, status) {
        if (status === 'OK') {
            if (results[0]) {
                initiLocation.city = results[0].formatted_address;
                initialLocation.city = results[0].formatted_address;
                $('#preferred-locality').val(initialLocation.city);
                return initiLocation.city;
            } else {
                console.log('No results found');
                if (getLocationName(initialLocation)) {
                    initialLocation.city = searchedCity;
                }
            }
        } else {
            console.log('Geocoder failed due to: ' + status);
            if (window.location.pathname === "/search.php") {
                initialLocation.city = searchedCity;
            }
        }
    });
}

*/


function AssistSubmit() {
		if (user) {

				/* Sending analytics value */
				// let assistSchoolType = $('input[name="school_type"][checked="checked"]').val();
				let assistSchoolType = parseInt($('#AssistantModal .school-selector.selected').attr('data-schooltypeid'));

				if (assistSchoolType === 1) {
						AssistantAnalyticsDaySchool();
				} else if (assistSchoolType === 2) {
						AssistantAnalyticsPreSchool();
				} else if (assistSchoolType === 3) {
						AssistantAnalyticsBoardingSchool();
				}


				let userID = user.id;
				// let grade = $('#gradeFilter').find('span.assist-tag.active-assist').attr('data-id');
				let grade = $('#AssistantModal .assistant-grade-wrapper .assistant-tag.selected').attr('data-id');

				// FEES MULTIPLE SELECTION
				let fee = [];
				$('#AssistantModal .assistant-fees-wrapper .assistant-tag.selected').each(function () {
						fee.push($(this).attr('data-filter'));
				});

				let assistFee = fee.join(',');


				// BOARDS
				let boards = [];
				$('#AssistantModal .assistant-board-wrapper .assistant-tag.selected').each(function () {
						boards.push($(this).attr('data-filter'));
				});
				let assistBoard = boards.join(',');

				// AGE
				let age = $('#AssistantModal .assistant-age-wrapper .assistant-tag.selected').attr('data-filter');


				// REGIONS
				let assistRegions = [];
				$('#AssistantModal .assistant-region-wrapper .assistant-tag.selected').each(function () {
						assistRegions.push($(this).attr('data-filter'));
				});
				let assistRegion = assistRegions.join(',');

				//Locality
				let locality = $('#preferred-locality').val().trim();

				let AssistLat = !!locality ? $('#preferred-latitude').val().trim() : '';
				let AssistLng = !!locality ? $('#preferred-longitude').val().trim() : '';

				let notify = $('#notification').val();

				let gender = $("input[name='gender']:checked").val();

				let user_action = '';
				if (typeof only_assistant_page === 'undefined') {
						user_action = '';
				} else {
						user_action = 'assistant_filled';
				}

				$.ajax({
						type: "POST",
						url: `${HOST}/assistant/server/assistantSubmit.php`,
						cache: false,
						data: {
								user: userID,
								fee: assistFee,
								school_type: assistSchoolType,
								address: locality,
								grade: grade,
								region: assistRegion,
								board: assistBoard,
								age: age,
								gender: gender,
								notification: notify,
								lead_home_location: ipCityName, /* */
								lead_home_lat: latitude, /* variable defined in preferred location page */
								lead_home_lng: longitude,  /*  */
								lead_lat: AssistLat,
								lead_lng: AssistLng,
								user_action: user_action
						},
						headers: {
								'Cache-Control': 'no-cache, no-store, must-revalidate',
								'Pragma': 'no-cache',
								'Expires': '0'
						},
						success: (response) => {
								//Phone leads cron job function call
								AssistantPhoneLeadsAssignCronJob(userID, function () {
										$('.button_1O').show();
										AssistantAnalyticsSubmitted();
										insertUserAnalytics('assistant', false, 'assistant submit');
										$('.modal-backdrop').remove();

										// let searchtype = $('input[name="school_type"][checked="checked"]').val();

										DayORBoardingSchoolSearchQuery(locality, AssistLat, AssistLng, assistSchoolType, !!gender ? gender : '');

								});

						}
				});
		}
}

function AssistantPhoneLeadsAssignCronJob(userId, callback) {

		$.ajax({
				url: `${HOST}/admin/lead_admin/crons/assign_phone_leads.php`,
				type: 'POST',
				data: {userid: userId},
				success: function (data) {
						if (DEBUG) console.log('success', data);
						callback(data);
				},
				error: function (data) {
						if (DEBUG) console.log('error', data);
						callback(data);
				}
		})

}


function AssistFinish() {
		if (user) {
				//getPrefLoc();

				// let Type = $('input[name="school_type"][checked="checked"]').val();
				let Type = $('#AssistantModal .school-selector.selected').attr('data-schooltypeid');

				if(Type != '3'){
						let locationInput = $("#prefsearchTextField1").val();
						/*if (!locationInput) {
								$("#prefsearchTextField1").addClass('border-danger').focus();
								return false;
						}*/
				}

				if (Type == '3') {
						// let genderAssistant = $("input[name='gender']:checked").val();
						let genderAssistant = $("#assist-login-form input[name='gender']:checked").val();

						if ((typeof (genderAssistant) == 'undefined')) {

								$('#genderErr').show();
								return false;
						}
				}

				$('#submitAssitant').css('pointer-events','none').html(`Loading <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>`);

				/*if(!$('#submitAssitant').is(':visible')){
						$('#FinishAssistant').prop('disabled', true).hide();
						$(`<button class="btn btn-success finish-btns" type="button" disabled>
							<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							Loading...
						</button>`).insertAfter('#FinishAssistant');
				}*/

				assistantSubmitOnLogin();
				// $('#AssistantModal').modal('hide');

		} else {
				$('#assist_login_phone').focus();
				$('.errorFinish').html('Please Login to continue ');
		}
}

function showLoaderCoverAssist() {
		$('#cover-spin-assist').show();
}

function hideLoaderCoverAssist() {
		$('#cover-spin-assist').hide();
}


