let assistantUserData = '';
function getUserAssistantDetails() {
		$.ajax({
				url: `${HOST}/assistant/server/assistantGetDetails.php`,
				type: "POST",
				dataType: "json",
				success: function (assistData) {
						AssistLoginHideShow();
						// $('.button_1O').hide();

						$('#preferred-latitude').val(assistData.lat);
						$('#preferred-longitude').val(assistData.lng);

						assistantUserData = assistData;

						if(!!assistantUserData.lead_location) getCityStateDataByAddress(assistantUserData.lead_location);

						// singleGrade();

						if (!assistData) {
								return false;
						} else {
								AssistUserSchoolType(assistData.lead_school_type);

								if (assistData.lead_school_type === 1) {
										if (assistData.child_1_grade !== "") AssistUserGrade(assistData.child_1_grade);
										if (assistData.child1_board !== "") AssistUserBoard(assistData.child1_board);
										if (assistData.lead_fees !== "") AssistUserfees(assistData.lead_fees);

										// AssistUserDayQueryText();
										locality(assistData.lead_location);
										getNotification(assistData.notification);

								} else if (assistData.lead_school_type === 5) {

										if (assistData.child_1_grade !== "") AssistUserGrade(assistData.child_1_grade);
										if (assistData.child1_board !== "") AssistUserBoard(assistData.child1_board);
										if (assistData.lead_fees !== "") AssistUserfees(assistData.lead_fees);

										AssistUserPuQueryText();
										locality(assistData.lead_location);
										getNotification(assistData.notification);

								} else if (assistData.lead_school_type === 2) {

										AssistUserAge(assistData.child_1_dob);
										if (assistData.lead_fees !== "") {
												AssistUserfees(assistData.lead_fees);
										}
										AssistUserPreQueryText();
										locality(assistData.lead_location);
										getNotification(assistData.notification);

								} else if (assistData.lead_school_type === 3) {

										if (assistData.child_1_grade !== "") AssistUserGrade(assistData.child_1_grade);
										if (assistData.child1_board !== "") AssistUserBoard(assistData.child1_board);
										if (assistData.lead_fees !== "") AssistUserfees(assistData.lead_fees);
										if (assistData.child1_region !== "") AssistUserRegion(assistData.child1_region);

										AssistUserBoardingQueryText();
										locality(assistData.lead_location);
										getGender(assistData.child_1_gender);
										getNotification(assistData.notification);

								}
						}
				}
		});
}

let getCityStateDataByAddressData = '';
function getCityStateDataByAddress(address){
		$.ajax({
				type: "GET",
				url: `${HOST}/common/cities/server/routes/api_get_city_state_by_address.php`, /* users/user_track/user_track_insert.php replaced */
				data: {
						'formattedAddress': address
				},
				success: function(data){
						getCityStateDataByAddressData = data;
						return false;
				}
		});
}

function AssistLoginHideShow() {
		if (user) {
				$('#assist-login-form').hide();
				$('#submitAssitant').text('Get my options');
		}

}

function AssistUserSchoolType(type) {
		// if (DEBUG) console.log('AssistUserSchoolType');
		// $('input[name="school_type"][value=' + type + ']').click();
		$(`#AssistantModal .school-selector[data-schooltypeid=${type}]`).trigger('click');
}

function AssistUserfees(fees) {
		if (fees === null) return;
		fees.split(',')
				.forEach((fee) => $(`#AssistantModal .assistant-fees-wrapper .assistant-tag[data-filter=${fee}]`)
						.trigger('click'));
}

function AssistUserGrade(grade) {
		if (grade === null) return;
		$(`#AssistantModal .assistant-grade-wrapper .assistant-tag[data-filter=${grade}]`).trigger('click');
}

function AssistUserBoard(boards) {
		if (boards === null) return;
		boards.split(',')
				.forEach((board) => $(`#AssistantModal .assistant-board-wrapper .assistant-tag[data-filter=${board}]`)
						.trigger('click'));

}

function AssistUserRegion(regions) {
		if (regions === null) return;
		regions.split(',')
				.forEach((region) => $(`#AssistantModal .assistant-region-wrapper .assistant-tag[data-filter=${region}]`)
						.trigger('click'));
}

function AssistUserAge(age) {
		function monthDiff(d1, d2) {
				let months;
				months = (d2.getFullYear() - d1.getFullYear()) * 12;
				months -= d1.getMonth() + 1;
				months += d2.getMonth();
				selectMonth(months);
				return months <= 0 ? 0 : months;
		}

		let date1 = age.replace(/-/g, ',');
		let d = new Date();
		let today = d.getFullYear() + "," + (d.getMonth() + 1) + "," + d.getDate();

		monthDiff(new Date(date1), new Date(today));

		function selectMonth(months) {
				if (months <= "12") $('#ageFilter span[data-age ="12"]').addClass('active-assist');
				else if (months > "12" && months <= "18") $('#ageFilter span[data-age ="18"]').addClass('active-assist');
				else if (months > "18" && months <= "30") $('#ageFilter span[data-age ="30"]').addClass('active-assist');
				else if (months > "30" && months <= "36") $('#ageFilter span[data-age ="36"]').addClass('active-assist');
		}
}

function locality(address) {
		$('#preferred-locality').val(address);
}

function AssistUserDayQueryText() {
		gradeText();
		boardstext();
		feestext();
}

function AssistUserPuQueryText() {
		boardstext();
		feestext();
}

function AssistUserPreQueryText() {
		agetext();
		feestext();
}

function AssistUserBoardingQueryText() {
		gradeText();
		boardstext();
		feestext();
		assistRegionText();
}


function singleGrade() {
		$('#filter_grade span.assist-tag').click(function () {
				$(this).addClass('active-assist');
				$(this).siblings().removeClass('active-assist');
		});
}

function getNotification(notify) {
		if (notify === 1) $('#notification').val('1');
		else {
				$('#notification').val('0');
				$("#notification").prop('checked', false);
		}
}

function getGender(gender) {
		if (gender === 'male,co-ed') $('input[name="gender"][value="male,co-ed"]').prop('checked', true);
		else if (gender === 'female,co-ed') $('input[name="gender"][value="female,co-ed"]').prop('checked', true);
}
