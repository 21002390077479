/**
 * Created by edust on 9/3/2018.
 */

$('#AssistantHeader').click(function () {
	gtag('event', {
		event_category: 'Assistant',
		event_action: 'Assistant Header clicked',
		event_label: 'Assistant header button Clicked'
	});
});

assistantModalElm.on('shown.bs.modal', function () {
	gtag('event', {
		event_category: 'Assistant',
		event_action: 'Assistant Open',
		event_label: 'Assistant Open'
	});
});


function AssistantManualClose() {
	gtag('event', {
		event_category: 'Assistant',
		event_action: 'Assistant close clicked',
		event_label: 'Assistant close clicked'
	});
}

assistantModalElm.on('hidden.bs.modal', function () {
	gtag('event', {
		event_category: 'Assistant',
		event_action: 'Assistant Closed',
		event_label: 'Assistant Closed'
	});
});

function AssistantAnalyticsDaySchool() {
	gtag('event', {
		event_category: 'Assistant',
		event_action: 'Day School Assistant Submitted',
		event_label: 'Day school Assistant Submitted'
	});
}

function skipAssistAnalytics() {
	gtag('event', {
		event_category: 'Assistant',
		event_action: 'Skip button clicked',
		event_label: 'skip button clicked'
	});
}

function AssistantAnalyticsPreSchool() {
	gtag('event', {
		event_category: 'Assistant',
		event_action: 'Pre School Assistant Submitted',
		event_label: 'Pre school Assistant Submitted'
	});
}

function AssistantAnalyticsBoardingSchool() {
	gtag('event', {
		event_category: 'Assistant',
		event_action: 'Boarding School Assistant Submitted',
		event_label: 'Boarding school Assistant Submitted'
	});
}

function AssistantAnalyticsSubmitted() {
	gtag('event', {
		event_category: 'Assistant',
		event_action: 'Assistant Submitted',
		event_label: 'Assistant Submitted'
	});
}
